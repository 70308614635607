import React from "react";
import { useNavigate } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import UndoIcon from '@material-ui/icons/Undo';
import ColoredButton from 'sfl-components/Buttons/Button';

import logo from "assets/images/logo-1.png";
import sideImage from "assets/images/loginSideCentered.jpg";

import Layout from '../Layout';
import { FormattedMessage } from 'react-intl';

export default function SignInForm() {
	const navigate = useNavigate();

	return <Layout sideImage={sideImage}>
		<img src={logo} alt='Simply For Life' style={{ width: '100%' }} />
		<div style={{ margin: '2rem 0 2rem 0' }}>
			<FormattedMessage
				id="sessionExpired.intro"
				defaultMessage="Your session has expired. Please return to the sign in page to continue."
			/>
		</div>
		<hr />
		<Grid item xs={12} style={{ padding: '1rem 0', textAlign: 'center' }}>
			<ColoredButton variant="contained" color="purple" startIcon={<UndoIcon />} onClick={() => navigate('../sign-in')}>
				<FormattedMessage
					id="sessionExpired.returnToLogin"
					defaultMessage="Return To Sign In"
				/>
			</ColoredButton>
		</Grid>
	</Layout >
}