import * as React from "react"

export default function Tab({ active = false, name, onClick, activeColor = 'green' }) {
    let activeTabClass = 'tabActive'
    if (activeColor === 'red') {
        activeTabClass = 'tabActiveRed'
    } else if (activeColor === 'grey') {
        activeTabClass = 'tabActiveGrey'
    }

    return <div className={`tab ${active && activeTabClass}`} onClick={onClick}>
        {name}
    </div>
}