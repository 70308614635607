import React, { useState } from "react";
import Tabs from 'v2/components/Tabs'
import Tab from 'v2/components/Tab'
import { toast } from 'react-toastify';
import TextField from 'sfl-components/TextField/Outlined';
import { useSFLApi } from 'api';

import Dialog from 'v2/components/Dialog';
import { FormattedMessage } from 'react-intl';

const today = new Date()
const currentDate = today.toISOString().split('T')[0]

export default function RecipeCatalogueDialog({ open, date = currentDate, onClose, onSave }) {
    const [scale, setScale] = useState('cup')
    const [quantity, setQuantity] = useState('1')
    const [mode, setMode] = useState('basic')
    const [basicOption, setBasicOption] = useState('1cup')
    const { post } = useSFLApi();

    const handleNumericChange = (key, val) => {
        setQuantity(val.replace(/[^0-9]/g, ''))
    }

    const handleSave = async () => {
        try {
            await post(`waterLogs`, {
                date: date,
                portion: quantity,
                scale: scale
            })
            onSave()
            toast.success('Water Added')
        }
        catch (error) {
            console.log('error', error)
            toast.error('Error')
        }
    }

    if (mode === 'basic') {
        return <Dialog open={open} onClose={onClose} maxWidth='sm' title="Water Tracking">
            <div>
                <Tabs style={{ marginBottom: '20px' }}>
                    <Tab
                        name="1 Cup"
                        active={basicOption === '1cup'}
                        onClick={() => {
                            setBasicOption('1cup')
                            setScale('cup')
                            setQuantity('1')
                        }}
                    />
                    <Tab
                        name="600ml"
                        active={basicOption === '600ml'}
                        onClick={() => {
                            setBasicOption('600ml')
                            setScale('ml')
                            setQuantity('600')
                        }}
                    />
                    <Tab
                        name="1 Litre"
                        active={basicOption === '1litre'}
                        onClick={() => {
                            setBasicOption('1litre')
                            setScale('litre')
                            setQuantity('1')
                        }}
                    />
                    <Tab
                        name="Other"
                        active={basicOption === 'Other'}
                        onClick={() => {
                            setBasicOption('other')
                            setMode('custom')
                        }}
                    />
                </Tabs>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '140px' }}>
                <div className='greenButton' onClick={handleSave}>
                    Add Water
                </div>
            </div>
        </Dialog>
    }

    return <Dialog open={open} onClose={onClose} maxWidth='sm' title="Water Tracking">
        <div style={{ display: 'flex', paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid #eeeeee', marginTop: '50px' }}>
            <div style={{ flex: 1, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <FormattedMessage id="questionnaireV2.sefsef" defaultMessage="Quantity" />
            </div>
            <div style={{ width: '200px' }}>
                <TextField
                    id="current_weight"
                    type="number"
                    value={quantity}
                    onChange={handleNumericChange}
                    inputProps={{ style: { padding: 10 } }}
                />
            </div>
        </div>
        <div>
            <Tabs style={{ marginBottom: '20px' }}>
                <Tab
                    name={<FormattedMessage id="app.scales.cup" defaultMessage="Cup" />}
                    active={scale === 'cup'}
                    onClick={() => { setScale('cup') }}
                />
                <Tab
                    name={<FormattedMessage id="app.scales.ml" defaultMessage="ml" />}
                    active={scale === 'ml'}
                    onClick={() => { setScale('ml') }}
                />
                <Tab
                    name={<FormattedMessage id="app.scales.litre" defaultMessage="Litre" />}
                    active={scale === 'litre'}
                    onClick={() => { setScale('litre') }}
                />
                <Tab
                    name={<FormattedMessage id="app.scales.ounce" defaultMessage="Oz" />}
                    active={scale === 'oz'}
                    onClick={() => { setScale('oz') }}
                />
            </Tabs>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '140px' }}>
            <div className='greenButton' onClick={handleSave}>
                Add Water
            </div>
        </div>
    </Dialog>
}