import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";

const dashboardRoutes = [
	{
		path: "dashboard",
		name: "Dashboard",
		icon: Dashboard
	},
	{
		path: "beta/recipes",
		name: "Recipes (beta)",
		icon: Dashboard
	},
	{
		path: "admin/resource-center-categories",
		name: "Resource Center Categories",
		icon: Person
	},
	{
		path: "admin/resource-center-pages",
		name: "Resource Center Pages",
		icon: Person
	},
	{
		path: "resource-center",
		name: "Resource Center",
		icon: Person
	},
	{
		path: "articles",
		name: "Articles",
		icon: Person
	},
	{
		path: "generators",
		name: "Generators",
		icon: LibraryBooks
	},
	{
		path: "meal-generators",
		name: "Generators (Meals)",
		icon: LibraryBooks
	},
	{
		path: "meal-plan-templates",
		name: "Meal Plan Templates",
		icon: Person
	},
	{
		path: "food-categories",
		name: "Food Categories",
		icon: LibraryBooks
	},
	{
		path: "grocery-categories",
		name: "Grocery Categories",
		icon: LibraryBooks
	},
	{
		path: "foods",
		name: "Foods",
		icon: LibraryBooks
	},
	{
		path: "medical-condition-categories",
		name: "Medical Condition Categories",
		icon: LibraryBooks
	},
	{
		path: "medical-conditions",
		name: "Medical Conditions",
		icon: LibraryBooks
	},
	{
		path: "admin/recipe-categories",
		name: "Recipe Categories",
		icon: LibraryBooks
	},
	{
		path: "admin/recipes",
		name: "Recipes",
		icon: LibraryBooks
	},
	{
		path: "recipe-categories",
		name: "Recipe Catalogue",
		icon: Dashboard
	},
	{
		path: "locations",
		name: "Locations",
		icon: LibraryBooks
	},
	{
		path: "members",
		name: "Members",
		icon: LibraryBooks
	},
	{
		path: "consultants",
		name: "Consultants",
		icon: LibraryBooks
	},
	{
		path: "admins",
		name: "Admins",
		icon: LibraryBooks
	}
]

export default dashboardRoutes;
