import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import LoginIcon from '@material-ui/icons/ExitToApp';

import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import NavBarButton from "sfl-components/Navbars/NavBarButton.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColoredButton from 'sfl-components/Buttons/Button';

import logo from "assets/images/logo-with-tagline.png";

import { useSFLApi } from 'api';
import { toast } from 'react-toastify';

import { FormattedMessage, useIntl } from 'react-intl';



export default function SignInForm() {
    const [state, setState] = useState({});
    const { first_name = '', last_name = '', email = '' } = state;
    const { post } = useSFLApi();
    const navigate = useNavigate();
    const intl = useIntl();


    const handleChange = (name, value) => {
        setState(currentState => ({ ...currentState, [name]: value }))
    }

    const preventDefault = (event) => {
        event.preventDefault();
    }

    const submitLogin = () => {
        post('actions/passwordReset', state)
            .then(() => {
                setState({})
                toast.success(intl.formatMessage({ id: "forgotLogin.emailSent", defaultMessage: "Reset Email Sent!" }), {
                    toastId: 'ForgotLoginSuccess'
                })
            })
            .catch(() => toast.error(intl.formatMessage({ id: "forgotLogin.accountNotFound", defaultMessage: 'Account not found!' }), {
                toastId: 'ForgotLoginInvalidLogin'
            }));
    }



    return <form onSubmit={preventDefault} action="/">
        <MobileNavbar style={{ backgroundColor: 'white', color: 'black', boxShadow: 'none' }}>
            <NavBarButton onClick={navigate('../sign-in')}>
                <FontAwesomeIcon flip='horizontal' icon={['fas', 'share']} fixedWidth />
            </NavBarButton>
            <NavBarTitle>
                <FormattedMessage id="forgotLogin.recoverYourAccount" defaultMessage="Recover Your Account" />
            </NavBarTitle>
        </MobileNavbar>
        <div style={{ padding: '80px 30px 10px 30px' }}>
            <img src={logo} alt='Simply For Life' style={{ width: '100%' }} />
        </div>
        <div style={{ padding: '20px 30px', fontWeight: 'bold' }}>
            <FormattedMessage id="forgotLogin.accountDetails" defaultMessage="Account Details" />
        </div>
        <div style={{ padding: '20px 30px' }}>
            <TextField
                id="first_name"
                label={intl.formatMessage({ id: "app.firstName", defaultMessage: "First Name" })}
                value={first_name}
                fullWidth
                variant="outlined"
                onChange={event => handleChange('first_name', event.target.value)}
            />
        </div>
        <div style={{ padding: '20px 30px' }}>
            <TextField
                id="last_name"
                label={intl.formatMessage({ id: "app.lastName", defaultMessage: "Last Name" })}
                value={last_name}
                fullWidth
                variant="outlined"
                onChange={event => handleChange('last_name', event.target.value)}
            />
        </div>
        <div style={{ padding: '20px 30px' }}>
            <TextField
                id="email"
                label={intl.formatMessage({ id: "app.email", defaultMessage: "Email" })}
                value={email}
                fullWidth
                variant="outlined"
                onChange={event => handleChange('email', event.target.value)}
            />
        </div>
        <div style={{ padding: '10px 30px' }}>
            <ColoredButton color='purple' type="submit" style={{ width: '100%', marginBottom: '2rem' }} startIcon={<LoginIcon />} onClick={submitLogin}>
                <FormattedMessage id="app.reset" defaultMessage="Reset" />
            </ColoredButton>
        </div>
        <div style={{ padding: '10px 30px', textAlign: 'center' }}>
            <FormattedMessage id="forgotLogin.dontHaveAccountYet" defaultMessage="Don't have an account yet?" />
        </div>
        <div style={{ padding: '0px 30px', textAlign: 'center' }}>
            <a href='http://simplyforlife.com/#consultant' target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="forgotLogin.signupAtWebsite" defaultMessage="Sign up at SimplyForLife.com" />
            </a>
        </div>
    </form >
}