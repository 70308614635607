import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: 'flex-start',
    },
    topPaperScrollBody: {
        verticalAlign: 'top',
    },
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open = true, onClose, maxWidth = false, title = 'title', children }) {
    const classes = useStyles()
    
    const handleClose = () => {
        if (onClose) {
            onClose()
        }
    }

    return <Dialog
        fullWidth={true}
        classes={{
            scrollPaper: classes.topScrollPaper,
            paperScrollBody: classes.topPaperScrollBody,
        }}
        maxWidth={maxWidth} open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar style={{ position: 'relative' }}>
            <Toolbar style={{ display: 'flex', backgroundColor: '#17B169' }}>
                <div style={{ flex: 1, fontSize: '20px', fontWeight: 500 }}>
                    {title}
                </div>
                <div>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
        <DialogContent style={{ backgroundColor: '#eeeeee', paddingTop: '30px' }}>
            {children}
        </DialogContent>
    </Dialog>
}
