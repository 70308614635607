import React, { useState, useEffect, Suspense } from "react";
import { useSelector, useDispatch } from 'react-redux'

// core components
import Member from "layouts/Member.js";
import { useSFLApi } from 'api';
import { clearSessionToken } from 'store/session'

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ConfirmationDialogStateProvider from "sfl-components/Dialog/ConfirmationDialogStateProvider"
import EmptyLayout from './EmptyLayout.js'


import SignIn from 'views-public/SignIn';
import ForgotLogin from 'views-public/ForgotLogin';
import ResetPassword from 'views-public/ResetPassword';
import TermsOfUse from 'views-public/TermsOfUse';
import PrivacyPolicy from 'views-public/PrivacyPolicy';
import SessionExpired from 'views-public/SessionExpired';
import QuestionnaireSignup from 'views-public/QuestionnaireSignup';
import QuestionnaireV2 from "v2/routes/questionnaire";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const Admin = React.lazy(() => import("layouts/Admin.js"));
const Consultant = React.lazy(() => import("layouts/Consultant.js"));

const theme = createTheme({
    palette: {
        primary: { main: "#6b3374", contrastText: "#fff" },
        secondary: { main: "#03a9f4", contrastText: "#fff" }
    },
    overrides: {
        // Style sheet name ⚛️
        MuiFilledInput: {
            // Name of the rule
            root: {
                // Some CSS
                backgroundColor: 'white',
            },
        },
    },
})




const RootLayout = () => {
    const { token, userType } = useSelector(state => state.session);
    const [tokenValidated, setTokenValidated] = useState();
    const dispatch = useDispatch();
    const { get } = useSFLApi();

    useEffect(() => {
        const validateToken = async () => {
            if (token === '') {
                setTokenValidated(false)
                return;
            }
            try {
                const { session = {} } = await get(`sessions/${token}`)
                if (session.status === 'active') {
                    setTokenValidated(true);
                } else {
                    dispatch(clearSessionToken());
                }
            }
            catch (error) {
                dispatch(clearSessionToken());
                console.log(error)
            }
        }

        validateToken()

    }, [token, get, dispatch])

    if (tokenValidated === undefined) {
        return <div />
    } else if (tokenValidated === true) {
        return <ThemeProvider theme={theme}>
            <ConfirmationDialogStateProvider>
                {userType === 'admin' && <Suspense fallback={null}><Admin /></Suspense>}
                {userType === 'consultant' && <Suspense fallback={null}><Consultant /></Suspense>}
                {userType === 'member' && <Suspense fallback={null}><Member /></Suspense>}
            </ConfirmationDialogStateProvider>
        </ThemeProvider >
    } else if (tokenValidated === false) {
        const router = createBrowserRouter([
            {
                path: '/',
                element: <SignIn />
            },
            {
                path: 'sign-in',
                element: <SignIn />
            },
            {
                path: 'forgot-login',
                element: <ForgotLogin />
            },
            {
                path: 'reset-password/:passwordResetToken',
                element: <ResetPassword />
            },
            {
                path: 'terms-of-use',
                element: <TermsOfUse />
            },
            {
                path: 'privacy-policy',
                element: <PrivacyPolicy />
            },
            {
                path: 'session-expired',
                element: <SessionExpired />
            },
            {
                path: 'questionnaire-signup/:tokenUuid',
                element: <QuestionnaireSignup />
            },
            {
                path: 'member-questionnaire/:tokenUuid',
                element: <QuestionnaireV2 />
            },
            {
                path: ':lang?',
                element: <EmptyLayout />,
                children: [
                    {
                        index: true,
                        element: <SignIn />
                    },
                    {
                        path: 'sign-in',
                        element: <SignIn />
                    },
                    {
                        path: 'forgot-login',
                        element: <ForgotLogin />
                    },
                    {
                        path: 'reset-password/:passwordResetToken',
                        element: <ResetPassword />
                    },
                    {
                        path: 'terms-of-use',
                        element: <TermsOfUse />
                    },
                    {
                        path: 'privacy-policy',
                        element: <PrivacyPolicy />
                    },
                    {
                        path: 'session-expired',
                        element: <SessionExpired />
                    },
                    {
                        path: 'questionnaire-signup/:tokenUuid',
                        element: <QuestionnaireSignup />
                    },
                    {
                        path: 'member-questionnaire/:tokenUuid',
                        element: <QuestionnaireV2 />
                    },
                    {
                        path: '*',
                        element: <SignIn />
                    }
                ]
            },
            {
                path: '*',
                element: <SignIn />
            }
        ])

        return <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    } else {
        return <div />
    }
}

export default RootLayout;